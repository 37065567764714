import React, { useContext } from 'react';
import './NotificationCard.scss';
import Button from '../Button/Button';
import displayPicture from '../../assets/defaults/displayPicture.png'
import { Link } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import { XIcon } from './xsvg';
import { formatTimestamp } from '../../utils/dateUtils';

const NotificationCard = ({ notification, handleAccept, handleDecline, handleDeleteNotification }) => {
  const { read, userFrom, message, createdAt, notificationType } = notification;
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  let link = '';
  if (notificationType === 'connected' || notificationType === 'connect' || message.includes('connect request')) {
    link = `/${userFrom.username}/`;
  } else if (notificationType === 'decline') {
    link = `/campaigns/past/`;
  } else if (notificationType === 'accept') {
    link = `/campaigns/${notification.entityID}`;
  } else if (notificationType === 'campaign') {
    link = `/campaigns/${notification.entityID}`;
  } else if (notificationType === 'request') {
    link = `/campaigns/requests/`;
  } else if (notificationType === 'message') {
    link = `/messages/${notification.entityID}`;
  } else if (notificationType === 'donation') {
    link = `/donations/`;
  }
  

  return (
    <div className={`notification-card ${read ? 'read' : 'unread'} notification-card--${themeClass}`}>
      <div >
        <div className="notification-card__content">
          <img src={userFrom.picture === "/avatar.png" ? displayPicture : userFrom.picture} className="notification-card__img" alt="user" />
          <Link to={link} className={`notification-card__message notification-card__message--${themeClass}`}>{message}</Link>
          <div className={`notification-card__buttons--d notification-card__buttons ${notificationType === 'connect' ? 'notification-card__buttons--connect' : ''}`}>
            {notificationType === 'connect' && (
              <>
                <Button modifier="notification-card__button button--user" onClick={() => handleAccept(notification._id, userFrom)}>Confirm</Button>
                <Button modifier="notification-card__button button--user" onClick={() => handleDecline(notification._id, userFrom)}>Delete</Button>
              </>
            )}
          </div>
          <div className={`notification-card__timestamp notification-card__timestamp--${themeClass}`}>{formatTimestamp(createdAt)}</div>
          {!notification.read && (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill={"#9ac9ff"} className="icon icon-tabler icons-tabler-filled icon-tabler-point">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" />
            </svg>
          )}
          <div style={{ marginLeft: '0.75rem', opacity: '0.5' }} onClick={() => { handleDeleteNotification(notification._id) }}>
            <XIcon themeMode={themeMode} />
          </div>
        </div>
      </div>
      <div className={`notification-card__buttons--m notification-card__buttons ${notificationType === 'connect' ? 'notification-card__buttons--connect' : ''}`}>
        {notificationType === 'connect' && (
          <>
            <Button modifier="button--user" onClick={() => handleAccept(notification._id, userFrom)}>Confirm</Button>
            <Button modifier="button--user" onClick={() => handleDecline(notification._id, userFrom)}>Delete</Button>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;
